<template>
  <div
    class="page-search"
    v-if="searchForm && searchForm.items && searchForm.items.length > 0"
  >
    <el-form
      ref="searchForm"
      :model="searchForm.data"
      :inline="true"
      label-position="right"
      :label-width="searchForm.labelWidth"
    >
      <template v-for="item in searchForm.items">
        <search-form-item
          :key="item.formItemID"
          :form="searchForm.data"
          :item="item"
          :class="{
            'collapse-block':
              showCollapse && !visibleSearchProps.includes(item.prop),
          }"
        ></search-form-item>
      </template>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" icon="el-icon-search" @click="query">
            查询
          </el-button>
          <el-button plain icon="el-icon-refresh-left" @click="resetSearch">
            重置
          </el-button>
          <el-button
            v-if="showCollapse"
            type="primary"
            :icon="isCollapsed ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"
            @click="collapse"
          >
            {{ isCollapsed ? "展开" : "收起" }}
          </el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "SearchForm",
  props: {
    searchForm: {
      type: Object,
      require: true,
    },
    showCollapse: {
      type: Boolean,
      default: false,
    },
    visibleSearchProps: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isCollapsed: true,
    };
  },
  methods: {
    query() {
      this.$emit("handleQuery");
    },
    resetSearch() {
      this.$refs.searchForm.resetFields();
    },
    collapse() {
      this.isCollapsed = !this.isCollapsed;
      (document.querySelectorAll(".collapse-block") || []).forEach(
        (selector) => {
          selector.style.display = this.isCollapsed ? "none" : "inline-block";
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page-search {
  border-top: 1px solid #dcdfe6;
  padding-top: 7px;

  .el-form {
    display: inline-block;
    vertical-align: top;
    ::v-deep {
      .el-form-item {
        margin-bottom: 7px;
      }
    }
  }

  .collapse-block {
    display: none;
  }
}
</style>