import defaultSettings from '@/config'

const { logo, logoUrl } = defaultSettings;

const state = () => ({
    logo,
    logoUrl,
    collapse: true
});

const getters = {
    logo: (state) => state.logo,
    logoUrl: (state) => state.logoUrl,
    collapse: (state) => state.collapse
};

const mutations = {
    changeCollapse: (state) => {
        state.collapse = !state.collapse;
    },
    foldSideBar: (state) => {
        state.collapse = true;
    },
    openSideBar: (state) => {
        state.collapse = false;
    }
};

const actions = {
    changeCollapse({ commit }) {
        commit('changeCollapse');
    },
    foldSideBar({ commit }) {
        commit('foldSideBar');
    },
    openSideBar({ commit }) {
        commit('openSideBar');
    }
};

export default { state, getters, mutations, actions }