import Vue from 'vue'
import './echarts'
import './element-ui'
import './ueditor-wrap';
import './wang-editor'
import '@/assets/scss/base.scss'
import '@/config/permission'
import global from '@/utils/global'
import 'remixicon/fonts/remixicon.css'

Vue.use(global)


