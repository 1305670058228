<template>
  <div id="layout-main" :style="style">
    <layout-tabs></layout-tabs>
    <div id="main-wrapper">
      <div id="main-container">
        <layout-breadcrumb />
        <layout-main-content />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutMain",
  props: {
    isCollapse: {
      type: Boolean,
      require: true,
    },
  },
  computed:{
    style:function(){
      if(this.isCollapse){
        return {
          'margin-left': '48px',
          'width': 'calc(100vw - 48px)'
        }
      }else{
        return {
          'margin-left': '202px',
          'width': 'calc(100vw - 202px)'
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#layout-main {
  height: 100%;
  background: rgba(233, 237, 241, 1);

  #main-wrapper {
    padding: 12px;
    height: calc(100% - 65px);
    overflow: auto;

    #main-container {
      background: white;
      border-radius: 4px;
      height: 100%;
    }
  }
}
</style>