<template>
  <div class="layout-wrapper">
    <layout-header />
    <layout-middle />
    <layout-footer />
  </div>
</template>

<script>
import { tokenName } from "@/config";
export default {
  name: "Layout",
  mounted() {
    this.$nextTick(() => {
      window.addEventListener(
        "storage",
        (e) => {
          if (e.key === tokenName && (e.key === null || e.value === null)) {
            window.location.reload();
          }
        },
        false
      );
    });
  },
};
</script>

<style lang="scss" scoped>
.layout-wrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .mask {
    background: #ffffff;
    bottom: 0;
    height: 100vh;
    left: 0;
    opacity: 0.5;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: $base-z-index - 1;
  }
}
</style>