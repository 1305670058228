import JSEncrypt from 'jsencrypt'
import { getPublicKey } from '@/api/user'

export async function encrypt(data) {
    /*
    const res = await getPublicKey();
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(res.data);
    return encrypt.encrypt(JSON.stringify(data));
    */
    return data;
}