var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.item.isHidden)?_c('el-form-item',{class:{ 'checkbox-inner': _vm.item.type == 'checkbox-inner' },attrs:{"label":_vm.item.label,"prop":_vm.item.prop}},[[(
        _vm.item.type == 'label' ||
        _vm.item.type == 'input' ||
        _vm.item.type == 'textarea'
      )?_c('el-input',{style:({ width: _vm.item.width }),attrs:{"disabled":_vm.item.type == 'label',"type":_vm.item.type == 'textarea' ? 'textarea' : 'text',"rows":_vm.item.type == 'textarea' ? 4 : 1},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'input-int' || _vm.item.type == 'input-number')?_c('el-input-number',{style:({ width: _vm.item.width }),attrs:{"controls-position":"right","precision":_vm.item.type == 'input-int' ? 0 : 3,"min":_vm.item.min ? _vm.item.min : 0,"max":_vm.item.max ? _vm.item.max : 9999999},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'select' || _vm.item.type == 'select-multi')?_c('el-select',{style:({ width: _vm.item.width }),attrs:{"multiple":_vm.item.type == 'select-multi',"collapse-tags":_vm.item.type == 'select-multi',"clearable":!_vm.item.isRequired,"filterable":"","placeholder":"请选择"},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}},_vm._l((_vm.itemOptions),function(option,optionIndex){return _c('el-option',{key:optionIndex,attrs:{"label":option.label,"value":option.value}})}),1):_vm._e(),(_vm.item.type == 'select-tree')?_c('select-tree',{style:({ width: _vm.item.width }),attrs:{"propName":_vm.item.prop,"prop":_vm.form[_vm.item.prop],"treeData":_vm.itemOptions},on:{"handleChangeTreeNode":_vm.handleChangeTreeNode}}):_vm._e(),(_vm.item.type == 'cascader')?_c('el-cascader',{style:({ width: _vm.item.width }),attrs:{"options":_vm.itemOptions,"filterable":"","clearable":"","collapse-tags":"","props":{
        checkStrictly: _vm.item.isCheckStrictly,
        multiple: _vm.item.isMultiple,
      },"show-all-levels":_vm.item.isShowAllLevels},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'checkbox')?_c('el-checkbox',{model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'checkbox-string')?_c('el-checkbox',{attrs:{"true-label":"1","false-label":"0"},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'checkbox-int')?_c('el-checkbox',{attrs:{"true-label":1,"false-label":0},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'checkbox-inner')?_c('el-checkbox',{style:({
        position: 'relative',
        left: '-30px',
        width: '0',
      }),model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'checkbox-inner-string')?_c('el-checkbox',{style:({
        position: 'relative',
        left: '-30px',
        width: '0',
      }),attrs:{"true-label":"1","false-label":"0"},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'checkbox-inner-int')?_c('el-checkbox',{style:({
        position: 'relative',
        left: '-30px',
        width: '0',
      }),attrs:{"true-label":1,"false-label":0},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'date')?_c('el-date-picker',{style:({ width: _vm.item.width }),attrs:{"type":"date","placeholder":_vm.item.prop.toLowerCase().indexOf('start') > -1
          ? '开始日期'
          : _vm.item.prop.toLowerCase().indexOf('end') > -1
          ? '结束日期'
          : '选择日期',"clearable":false},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e(),(_vm.item.type == 'datetime')?_c('el-date-picker',{style:({ width: _vm.item.width }),attrs:{"type":"datetime","value-format":"yyyy-MM-dd HH:mm:ss","placeholder":_vm.item.prop.toLowerCase().indexOf('start') > -1
          ? '开始时间'
          : _vm.item.prop.toLowerCase().indexOf('end') > -1
          ? '结束时间'
          : '选择日期时间'},model:{value:(_vm.form[_vm.item.prop]),callback:function ($$v) {_vm.$set(_vm.form, _vm.item.prop, $$v)},expression:"form[item.prop]"}}):_vm._e()]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }