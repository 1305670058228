import Vue from 'vue'
import { doLogin, doLogout } from '@/api/user'
import { getAccessToken, removeAccessToken, setAccessToken } from '@/utils/accessToken'
import { resetRouter } from '@/router'
import { tokenName } from '@/config'

const state = () => ({
  accessToken: getAccessToken(),
  loginRedirect: "",
  permissions: [],
});

const getters = {
  accessToken: (state) => state.accessToken,
  loginRedirect: (state) => state.loginRedirect,
  permissions: (state) => state.permissions,
};

const mutations = {
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
    setAccessToken(accessToken);
  },
  setloginRedirect(state, loginRedirect) {
    state.loginRedirect = loginRedirect;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
};

const actions = {
  setPermissions({ commit }, permissions) {
    commit("setPermissions", permissions);
  },
  setAccessToken({ commit }, accessToken) {
    commit("setAccessToken", accessToken);
  },
  async login({ commit }, userInfo) {
    const { data } = await doLogin(userInfo);
    if (data.token) {
      commit("setAccessToken", data.token);
    } else {
      Vue.prototype.$baseMessage(
        `登录接口异常，未正确返回${tokenName}...`,
        "error"
      );
      return;
    }
    commit("setloginRedirect", data.redirect ? data.redirect : "home");
  },
  async logout({ dispatch }) {
    await doLogout();
    await dispatch("resetAccessToken");
    await resetRouter();
  },
  resetAccessToken({ commit }) {
    commit("setPermissions", []);

    commit("setAccessToken", "");

    removeAccessToken();
  },
};

export default { state, getters, mutations, actions };
