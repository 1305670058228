<template>
  <component
    :is="menuComponent"
    v-if="item.meta.isMenu && !item.meta.isHidden"
    :item="item"
    :full-path="fullPath"
    :route-children="routeChildren"
  >
    <template v-if="item.children && item.children.length > 0">
      <navbar-item
        v-for="route in item.children"
        :key="route.path"
        :full-path="handlePath(route.path)"
        :item="route"
      />
    </template>
  </component>
</template>

<script>
import { isExternal } from "@/utils/validate";
import path from "path";

export default {
  name: "NavbarItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    fullPath: {
      type: String,
      default: "",
    },
  },
  computed: {
    menuComponent() {
      if (
        this.handleChildren(this.item.children, this.item) &&
        (!this.routeChildren.children || this.routeChildren.notShowChildren)
      ) {
        return "AsideMenuItem";
      } else {
        return "AsideSubMenu";
      }
    },
  },
  methods: {
    handleChildren(children = [], parent) {
      if (children === null) children = [];
      const showChildren = children.filter((item) => {
        if (!item.meta.isMenu || item.meta.isHidden) {
          return false;
        } else {
          this.routeChildren = item;
          return true;
        }
      });
      if (showChildren.length === 0) {
        this.routeChildren = {
          ...parent,
          path: "",
          notShowChildren: true,
        };
        return true;
      }
      return false;
    },
    handlePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.fullPath)) {
        return this.fullPath;
      }
      return path.resolve(this.fullPath, routePath);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-tag {
    border: 0;
    float: right;
    height: 16px;
    line-height: 16px;
    margin-top: calc((#{$base-menu-item-height} - 16px) / 2);
    padding-left: 4px;
    padding-right: 4px;
  }
}
</style>
