export function convertRouter(authRoutes) {
    return authRoutes.map((route) => {
        if (route.component) {
            if (route.component === 'Layout') {
                route.component = (resolve) => require(['@/views/components/layout'], resolve);
            }
            else if (route.component === 'EmptyLayout') {
                route.component = (resolve) => require(['@/views/components/layout/EmptyLayout'], resolve);
            }
            else {
                const index = route.component.indexOf('views');
                const path = index > 0 ? route.component.slice(index) : `views/components/${route.component}`;
                route.component = route.component.indexOf('/views/page/list') > 0 ? createCustomComponent(route.name, import(`@/${path}`)) : (resolve) => require([`@/${path}`], resolve);
            }
        }
        if (route.children) {
            if (route.children.length) {
                route.children = convertRouter(route.children);
            }
            else {
                delete route.children;
            }
        }
        return route;
    });
}

export function createCustomComponent(name, component) {
    return {
        name,
        data() {
            return { component: null }
        },
        async created() {
            if (component instanceof Promise) {
                try {
                    const module = await component
                    this.component = module?.default
                } catch (error) {
                    console.error(`can not resolve component ${name}, error:`, error)
                }

                return
            }
            this.component = component
        },
        render(h) {
            return this.component ? h(this.component) : null
        },
    }
}