<template>
  <vue-ueditor-wrap
    v-model="inputValue"
    @input="outInput"
    :config="ueditorConfig"
  ></vue-ueditor-wrap>
</template>

<script>
import { baseURL } from "@/config";
export default {
  name: "UeditorWrap",
  props: {
    value: String,
    config: Object,
  },
  data() {
    return {
      inputValue: "",
      ueditorConfig: {
        ...{
          UEDITOR_HOME_URL: "/static/plugins/ueditor/",
          serverUrl: baseURL + "/api/base/ueditor/upload",
          toolbars: [
            [
              "fullscreen",
              "source",
              "|",
              "undo",
              "redo",
              "|",
              "bold",
              "italic",
              "underline",
              "fontborder",
              "strikethrough",
              "superscript",
              "subscript",
              "removeformat",
              "formatmatch",
              "autotypeset",
              "blockquote",
              "pasteplain",
              "|",
              "forecolor",
              "backcolor",
              "insertorderedlist",
              "insertunorderedlist",
              "selectall",
              "cleardoc",
              "|",
              "rowspacingtop",
              "rowspacingbottom",
              "lineheight",
              "|",
              "customstyle",
              "paragraph",
              "fontfamily",
              "fontsize",
              "|",
              "directionalityltr",
              "directionalityrtl",
              "indent",
              "|",
              "justifyleft",
              "justifycenter",
              "justifyright",
              "justifyjustify",
              "|",
              "touppercase",
              "tolowercase",
              "|",
              "link",
              "unlink",
              "anchor",
              "|",
              "imagenone",
              "imageleft",
              "imageright",
              "imagecenter",
              "|",
              "simpleupload",
              "insertimage",
              "emotion",
              "scrawl",
              "insertvideo",
              "music",
              "attachment",
              "map",
              "insertframe",
              "pagebreak",
              "template",
              "background",
              "|",
              "horizontal",
              "date",
              "time",
              "spechars",
              "snapscreen",
              "wordimage",
              "|",
              "inserttable",
              "deletetable",
              "insertparagraphbeforetable",
              "insertrow",
              "deleterow",
              "insertcol",
              "deletecol",
              "mergecells",
              "mergeright",
              "mergedown",
              "splittocells",
              "splittorows",
              "splittocols",
              "charts",
              "|",
              "print",
              "preview",
              "searchreplace",
              "drafts",
              "help",
            ],
          ],
          initialFrameWidth: 900,
          initialFrameHeight: 500,
          enableAutoSave: false,
          autoSyncData: false,
          elementPathEnabled: false,
          maximumWords: 100000,
          scaleEnabled: true,
          autoFloatEnabled: false,
        },
        ...this.config,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.inputValue = this.value;
      },
    },
  },
  methods: {
    outInput() {
      this.$emit("input", this.inputValue);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>