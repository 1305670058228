<template>
  <el-dialog
    :title="title"
    :visible="visible"
    :close-on-click-modal="false"
    :width="formConfig.dialogWidth"
    @close="close"
  >
    <el-form :label-width="formConfig.labelWidth">
      <el-form-item :label="(formItem || {}).label || ''">
        <template slot-scope="">
          <span
            v-if="formItem && formItem.type && formItem.type.includes('file')"
          >
            <el-upload
              :ref="formItem.prop"
              :action="uploadUrl"
              :headers="headers"
              :data="param"
              :name="formItem.prop"
              :accept="
                formItem.type.includes('both')
                  ? '.xls,.xlxs,.txt'
                  : formItem.type.includes('excel')
                  ? '.xls,.xlxs'
                  : '.txt'
              "
              :on-success="(response) => uploadSuccess(response)"
              :on-error="uploadImgError"
              :auto-upload="false"
            >
              <el-button size="small" type="primary" :style="{ width: '120px' }"
                >选择文件</el-button
              >
            </el-upload>
          </span>
        </template>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确定</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { baseURL, defaultUploadUrl, successCode, tokenName } from "@/config";

export default {
  name: "SingleUpload",
  props: {
    formConfig: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      visible: false,
      param: {},
      title: "",
      formItem: {},
      headers: {
        Authorization: "Bearer " + this.$store.getters["user/accessToken"],
      },
    };
  },
  computed: {
    uploadUrl() {
      return (
        baseURL +
        (this.formItem.uploadUrl || defaultUploadUrl)
      );
    },
  },
  methods: {
    init(title = "", param = {}) {
      this.visible = true;
      this.$nextTick(() => {
        this.title = title;
        this.param = param || {};
        if (
          this.formConfig &&
          this.formConfig.items &&
          this.formConfig.items.length > 0
        ) {
          this.formItem = this.formConfig.items[0];
        }
      });
    },
    submit() {
      this.$refs[this.formItem.prop].submit();
    },
    uploadSuccess(response) {
      if (successCode.includes(response.code)) {
        this.$baseMessage("上传成功", "success");
        this.$emit("handleQuery");
        this.close();
      } else {
        this.$baseMessage("上传失败，" + response.msg, "error");
      }
      this.$refs[this.formItem.prop].clearFiles();
    },
    uploadImgError() {
      this.$baseMessage("上传失败", "error");
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
