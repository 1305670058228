<template>
  <div class="layout-sub-navbar" :style="{
    width: isCollapse ? '0px' : '154px',
  }">
    <div class="sub-menu">
      <el-menu :collapse-transition="false" mode="vertical">
        <template v-for="menu in menus">
          <template v-for="(subMenu, index) in menu.children">
            <navbar-item
              v-show="menu.meta.routeID == activeRouteID && subMenu.meta && subMenu.meta.isMenu && !subMenu.meta.isHidden"
               :key="'menu' + '-' + subMenu.meta.routeID + '-' + index" :full-path="menu.path + '/' + subMenu.path" :item="subMenu" />
          </template>
        </template>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutSubNavbar",
  props: {
    isCollapse: {
      type: Boolean,
      require: true,
    },
    menus: {
      type: Array,
      require: true,
    },
    activeRouteID: {
      type: String,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-sub-navbar {
  position: absolute;
  top: 0;
  left: 48px;
  height: 100%;
  width: 154px;
  font-size: 12px;
  background: white;
  overflow: auto;

  .sub-menu {
    min-height: 650px;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::v-deep {

    .el-submenu__title,
    .el-menu-item {
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid rgb(243 242 242);
    }
  }
}
</style>