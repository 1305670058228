<template>
  <div class="boder-box">
    <span class="boder-box-title">
      <el-checkbox @change="checkAllChange" title="全/反选" />
      {{ title }}
    </span>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "BorderBox",
  props: {
    pid: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  methods: {
    checkAllChange(val) {
      this.$emit("checkAllChange", { pid: this.pid, checked: val });
    },
  },
};
</script>
<style lang="scss" scoped>
.boder-box {
  margin: 20px 5px 5px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px 10px 3px 10px;
  position: relative;

  .boder-box-title {
    position: absolute;
    left: 10px;
    top: -15px;
    background: white;
    padding: 5px 8px;
  }
}
</style>