<template>
  <div class="grid-table">
    <el-table ref="gridTable" :data="tableData" stripe border fit show-header :show-summary="showSummary"
      :summary-method="handleGetSummaries" :row-style="handleRowStyle" :cell-style="handleCellStyle"
      :row-key="tableConfig.rowKey" :tree-props="{ children: 'Children', hasChildren: 'ExistsChildren' }"
      :row-class-name="tableConfig.rowClass ? handleRowClassName : () => { }" v-loading="loading" @select="handleSelect"
      @select-all="handleSelectAll" @selection-change="handleSelectionChange" @row-click="handleRowClick"
      @row-dblclick="handleRowDbClick" @sort-change="handleSortChange">
      <!-- 序列 -->
      <el-table-column v-if="showIndexColumn && tableConfig.columns && tableConfig.columns.length > 0" type="index"
        label="序号" width="50" :fixed="false" align="center"></el-table-column>
      <!-- 选择框 -->
      <el-table-column v-if="showCheckboxColumn && tableConfig.columns && tableConfig.columns.length > 0" type="selection"
        :fixed="false" align="center"></el-table-column>
      <!-- 普通列 -->
      <template v-for="(column, columIndex) in tableConfig.columns">
        <el-table-column v-if="!column.isHidden" :key="column.prop + columIndex" :index="columIndex" :label="column.label"
          :prop="column.prop" :width="column.width" :fixed="column.fixed ? column.fixed : false"
          :sortable="column.isSortable ? 'custom' : false" resizable show-overflow-tooltip
          :align="column.align || 'center'">
          <template v-if="column.headerMethod" slot="header">
            {{ column.label }}<el-tag @click="handleHeaderClick(column.headerMethod)">{{ column.headerMethodDesc
            }}</el-tag>
          </template>
          <template slot-scope="{ row, $index }">
            <!-- 默认展示 -->
            <span v-if="column.type == 'text'">
              {{ row[column.prop] }}
            </span>
            <span v-if="column.type == 'text-decimal'">
              {{ row[column.prop] | formatDecimal }}
            </span>
            <span v-if="column.type == 'text-datetime'" v-html="handleDatetime(row[column.prop], column.datetimeType)">
            </span>
            <!-- 求积 -->
            <span v-if="column.type == 'summary'">
              {{ handleSummary(row, column.summaryFields, column.prop) }}
            </span>
            <!-- html展示 -->
            <span v-if="column.type == 'html'" v-html="row[column.prop]"> </span>
            <!-- 可编辑input，仅在text默认展示类型才可编辑-->
            <el-input v-if="column.type == 'input'" v-model="row[column.prop]"
              @input="handleInput($event, column.method, $index)"></el-input>
            <el-input v-if="column.type == 'input-int'" v-model="row[column.prop]"
              oninput="value=value.match(/^\d*/g)[0] || 0"></el-input>
            <el-input v-if="column.type == 'input-decimal'" v-model="row[column.prop]"
              oninput="value=('' + value).replace(/[^\d^\.]+/g, '').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || ''"
              :disabled="column.checkDisableMethod ? handleCheckDisable(column.checkDisableMethod, row) : false"
              @input="handleInput($event, column.method, $index)">
            </el-input>
            <el-input v-if="column.type == 'input-decimal(3)'" v-model="row[column.prop]"
              oninput="value=('' + value).replace(/[^\d^\.]+/g, '').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,3})/g)[0] || ''"
              @input="handleInput($event, column.method, $index)">
            </el-input>
            <!-- switch开关 -->
            <el-switch v-if="column.type == 'switch'" v-model="row[column.prop]" disabled active-color="#86c440"
              @change="switchChange(row, $index, column.prop)"></el-switch>
            <el-switch v-if="column.type == 'switch-string'" v-model="row[column.prop]" active-value="1"
              inactive-value="0" disabled active-color="#86c440"
              @change="switchChange(row, $index, column.prop)"></el-switch>
            <el-switch v-if="column.type == 'switch-int'" v-model="row[column.prop]" :active-value="1" :inactive-value="0"
              disabled active-color="#86c440" @change="switchChange(row, $index, column.prop)"></el-switch>
            <!-- 多选框 -->
            <el-checkbox v-if="column.type == 'checkbox'" v-model="row[column.prop]"> </el-checkbox>
            <el-checkbox v-if="column.type == 'checkbox-string'" v-model="row[column.prop]" true-label="1"
              false-label="0"> </el-checkbox>
            <el-checkbox v-if="column.type == 'checkbox-int'" v-model="row[column.prop]" :true-label="1" :false-label="0">
            </el-checkbox>
            <!-- 图片展示 -->
            <el-image slot="reference" v-if="column.type == 'img'" :src="row[column.prop] || noImgUrl"></el-image>
            <span v-if="column.type == 'formatter'">
              <template v-if="column.formatter && column.formatter.type == 'text'">
                <span v-if="row[column.prop] in column.formatter.data">
                  {{ column.formatter.data[row[column.prop]] }}
                </span>
                <span v-else>
                  {{ row[column.prop] }}
                </span>
              </template>
              <template v-else-if="column.formatter && column.formatter.type == 'status'">
                <i v-if="row[column.prop] === true || row[column.prop] === 1 || row[column.prop] === '1'"
                  class="el-icon-success" :style="{ color: '#62cc62', 'font-size': '18px' }" />
                <i v-else class="el-icon-error" :style="{ color: '#b1b1b1', 'font-size': '18px' }" />
              </template>
              <template v-else>
                {{ row[column.prop] }}
              </template>
            </span>
            <!-- 操作按钮 -->
            <span v-if="column.type == 'operation'">
              <template v-if="row[column.prop]">
                {{ row[column.prop] }}
              </template>
              <template v-else>
                <template v-for="(button, index) in column.buttons">
                  <el-link :key="button.method + index" :type="button.type" :underline="false" :icon="button.icon"
                    @click.native.stop="rowOperation(row, $index, button.method)">{{ button.label }}</el-link>
                </template>
              </template>
            </span>
            <slot v-if="column.type == 'custom-operation'" :name="column.prop" :params="{ column: column, row: row }" />
          </template>
        </el-table-column>
      </template>
      <el-table-column v-if="showLastEmptyColumn"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <div v-show="showPagination" style=" {'textalign': 'left';  } ">
      <el-pagination small :hide-on-single-page="false" :current-page="pageNo" :pager-count="7"
        :page-sizes="[10, 15, 20, 30, 50]" :page-size="pageSize" background
        layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { tableRowActiveColor, noImgUrl } from '@/config';
import { isArray } from '@/utils/validate';
import { formatDatetime } from '@/utils/tool';
import { submit } from '@/api/base';

export default {
  name: 'GridTable',
  props: {
    tableConfig: {
      type: Object,
      required: true,
    },
    showIndexColumn: {
      type: Boolean,
      default: true,
    },
    showCheckboxColumn: {
      type: Boolean,
      default: true,
    },
    showLastEmptyColumn: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      if (document) {
        const gridTable = document.getElementsByClassName('grid-table')[0];
        const tableHeader = gridTable.getElementsByClassName('el-table__header-wrapper')[0];
        const tableBody = gridTable.getElementsByClassName('el-table__body-wrapper')[0];
        tableBody.style.maxHeight = document.getElementById('main-container').offsetHeight - this.$refs.gridTable.$el.offsetTop - tableHeader.offsetHeight - 40 + 'px';
        tableBody.style.overflow = 'auto';
      }
    });
    window.onresize = function () {
      if (document) {
        const gridTable = document.getElementsByClassName('grid-table')[0];
        const tableHeader = gridTable.getElementsByClassName('el-table__header-wrapper')[0];
        const tableBody = gridTable.getElementsByClassName('el-table__body-wrapper')[0];
        tableBody.style.maxHeight = document.getElementById('main-container').offsetHeight - that.$refs.gridTable.$el.offsetTop - tableHeader.offsetHeight - 40 + 'px';

      }
    };
  },
  data() {
    return {
      loading: false,
      pageNo: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      selectRows: [],
      searchParam: { ...((this.tableConfig.searchForm || {}).data || {}) },
      noImgUrl,
    };
  },
  methods: {
    setData(datas) {
      this.tableData = datas || [];
      this.total = this.tableData.length;
    },
    async fetchData(isCurrentChange = false, callback = null) {
      if (!isCurrentChange) {
        this.pageNo = 1;
      }
      this.loading = true;
      this.searchParam['currentPage'] = this.pageNo;
      this.searchParam['pageSize'] = this.showPagination ? this.pageSize : 9999999;
      const { data } = await submit(this.tableConfig.url, this.searchParam).catch((error) => {
        this.loading = false;
      });
      const { datas } = data;
      this.total = data.total;
      this.tableData = datas || [];
      this.loading = false;
      if (callback && typeof callback == 'function') {
        callback(this.tableData);
      }
    },
    handleSummary(row, fields, prop) {
      let data = 0;
      try {
        fields.forEach((field, index) => {
          if (index == 0) {
            data = row[field];
            return true;
          }
          data = data * row[field];
        });
      } catch {
        data = 0;
      }
      data = parseFloat(data.toFixed(2));
      row[prop] = data;
      return data;
    },
    handleGetSummaries(param) {
      let sums = [];
      this.$emit('getSummaries', param, (val) => {
        sums = val;
      });
      return sums;
    },
    handleRowClassName({ row }) {
      let className = '';
      this.$emit('handleRowClassName', row, (val) => {
        className = val;
      });
      return className;
    },
    handleCheckDisable(method, row) {
      let disabled = false;
      this.$emit(method, row, (val) => {
        disabled = val;
      });
      return disabled;
    },
    handleHeaderClick(method) {
      if (method) {
        this.$emit(method);
      }
    },
    handleInput(value, method, rowIndex) {
      if (method) {
        this.$emit(method, rowIndex, value);
      }
    },
    handleDatetime(value, datetimeType) {
      return formatDatetime(value, datetimeType);
    },
    async handleRowLoad(row, treeNode, resolve) {
      const { data } = await submit(this.tableConfig.rowLoadUrl, {
        id: row[this.tableConfig.rowKey],
      });
      if (isArray(data)) {
        resolve(data);
      }
    },
    // 条数变化
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    // 页码变化
    handleCurrentChange(current) {
      this.pageNo = current;
      this.fetchData(true);
    },
    // 勾选行的 Checkbox
    handleSelect(selection, row) {
      this.$emit('handleSelect', selection, row);
    },
    // 勾选全选 Checkbox
    handleSelectAll(selection) {
      this.$emit('handleSelectAll', selection);
    },
    //选项发生改变
    handleSelectionChange(selection) {
      this.selectRows = selection;
      this.$emit('selectionChange', selection);
    },
    // 点击行
    handleRowClick(row) {
      const parent = this.$emit('handleRowClick', row);
      if (!('handleRowClick' in parent._events)) {
        this.$refs.gridTable.toggleRowSelection(row);
      }
    },
    handleRowStyle({ row }) {
      if (this.selectRows.includes(row)) {
        return { 'background-color': tableRowActiveColor };
      }
    },
    handleCellStyle({ row }) {
      if (this.selectRows.includes(row)) {
        return {
          'background-color': tableRowActiveColor,
          'border-color': 'rgba(218, 223, 228, 1)',
        };
      }
    },
    // 双击行
    handleRowDbClick(row, column, event) {
      this.$emit('editRow', row);
    },
    //排序发生改变
    handleSortChange({ column, prop, order }) {
      this.searchParam['sortField'] = prop;
      this.searchParam['sortType'] = order == 'descending' ? 'desc' : 'asc';
      this.fetchData(1);
    },
    // 行操作
    rowOperation(row, index, method) {
      this.$emit('rowOperation', row, index, method);
    },
    // 可编辑input失去焦点
    editInputBlur(row, $index, prop, columIndex) {
      this.$emit('editInputBlur', row, $index, prop, columIndex);
    },
    // switchChange调用
    switchChange(row, $index, prop) {
      this.$emit('switchChange', row, $index, prop);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-table {
  .el-button {
    margin: 0 6px;
  }

  ::v-deep {
    .el-table__header {
      .el-tag {
        background-color: #3a8ee6;
        border-color: #3a8ee6;
        color: white;
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        font-size: 10px;
        cursor: pointer;
        margin-left: 2px;
      }
    }

    .el-table__header tr,
    .el-table__header th {
      padding: 0;
      height: 38px !important;
      line-height: 38px !important;
    }

    .el-image__inner {
      height: 50px;
      width: 50px;
    }

    .el-pagination__sizes {
      .el-input__inner {
        height: 22px;
      }

      .el-input__icon {
        line-height: 22px;
      }
    }

    .is-disabled {
      input {
        color: #878c96 !important;
      }
    }
  }
}
</style>
