import router from '@/router'
import store from '@/store'
import { getPageConfig } from "@/api/base";
import { getItem, setItem } from "@/utils/storage";
import {
    recordRoute,
    routesWhiteList
} from '@/config'

router.beforeResolve(async (to, from, next) => {
    if (routesWhiteList.indexOf(to.path) !== -1) {
        next();
    }
    else if (store.getters['user/accessToken']) {
        if (to.path === '/login') {
            next({ path: '/' });
            return;
        }
        const hasPermissions = store.getters['user/permissions'] && store.getters['user/permissions'].length > 0;
        if (hasPermissions) {
            if (to.meta && to.meta.routeID) {
                let pageConfig = {};
                let itemName = `route-${to.meta.routeTitle}(${to.meta.routeID})`;
                let pageConfigJson = null;//getItem(itemName);
                if (pageConfigJson == typeof undefined || pageConfigJson == null) {
                    const { data } = await getPageConfig({
                        routeID: to.meta.routeID,
                    });
                    pageConfig = data;
                    setItem(itemName, JSON.stringify(pageConfig));
                } else {
                    pageConfig = JSON.parse(pageConfigJson);
                }
                to.meta["pageConfig"] = pageConfig;
            }
            handleKeepAlive(to);
            next();
        } else {
            try {
                await store.dispatch('user/setPermissions', [store.getters['user/accessToken']])
                let accessRoutes = await store.dispatch('routes/setRoutes')
                accessRoutes.forEach((item) => {
                    router.addRoute(item)
                })
                next({ ...to, replace: true })
            } catch {
                await store.dispatch('user/resetAccessToken')
            }
        }     
    }
    else {
        next(recordRoute ? `/login?redirect=${to.path}` : '/login');
    }
    //document.title = getPageTitle(to.meta.title);
});

function handleKeepAlive(to) {
    if (to.matched && to.matched.length > 2) {
        for (let i = 0; i < to.matched.length; i++) {
            const element = to.matched[i];
            if (element.components.default.name === "EmptyLayout") {
                to.matched.splice(i, 1);
                i--;
            }
        }
    }
}

router.afterEach(() => {
})