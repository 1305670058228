<template>
  <div class="breadcrumb">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb.path"
      >
        {{ breadcrumb.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <i class="el-icon-question"></i>
    <el-divider></el-divider>
  </div>
</template>

<script>
export default {
  name: "LayoutBreadcrumb",
  data() {
    return {
      breadcrumbs: this.handleGetBreadcrumbs(),
    };
  },
  watch: {
    $route() {
      this.breadcrumbs = this.handleGetBreadcrumbs();
    },
  },
  methods: {
    handleGetBreadcrumbs() {
      return this.$route.matched.filter(
        (route) => route.name && route.meta && route.meta.title
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  position: relative;

  .el-breadcrumb {
    height: 30px;
    line-height: 30px;
    position: relative;
    padding-left: 10px;
    font-size: 14px;

    ::v-deep {
      .el-breadcrumb__item {
        .el-breadcrumb__inner {
          a {
            color: #515a6e;
            display: flex;
            float: left;
            font-weight: normal;
            i {
              margin-right: 3px;
            }
          }
        }

        .el-breadcrumb__separator{
          font-weight: unset;
        }

        &:last-child {
          .el-breadcrumb__inner {
            a {
              color: #999;
            }
          }
        }
      }
    }
  }

  .el-icon-question {
    color: rgb(45, 129, 208);
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 18px;
    cursor: pointer;
  }
}
</style>