const network = {
    baseURL: process.env.VUE_APP_SERVER ?? window.location.origin,
    contentType: "application/json;charset=UTF-8",
    method: "POST",
    requestTimeout: 50000,
    successCode: [200, 0],
    invalidCode: 402,
    noPermissionCode: 401,
    messageOffset: 100,
    messageDuration: 3000,
}

module.exports = network