<template>
  <el-form-item
    v-if="!item.isHidden"
    :label="item.label"
    :prop="item.prop"
    :class="{ 'checkbox-inner': item.type == 'checkbox-inner' }"
  >
    <template slot-scope="">
      <el-input
        v-if="
          item.type == 'label' ||
          item.type == 'input' ||
          item.type == 'textarea'
        "
        :disabled="item.type == 'label'"
        :type="item.type == 'textarea' ? 'textarea' : 'text'"
        :rows="item.type == 'textarea' ? 4 : 1"
        v-model.trim="form[item.prop]"
        :style="{ width: item.width }"
      >
      </el-input>
      <el-input-number
        v-if="item.type == 'input-int' || item.type == 'input-number'"
        v-model="form[item.prop]"
        controls-position="right"
        :precision="item.type == 'input-int' ? 0 : 3"
        :min="item.min ? item.min : 0"
        :max="item.max ? item.max : 9999999"
        :style="{ width: item.width }"
      ></el-input-number>
      <el-select
        v-if="item.type == 'select' || item.type == 'select-multi'"
        v-model="form[item.prop]"
        :multiple="item.type == 'select-multi'"
        :collapse-tags="item.type == 'select-multi'"
        :clearable="!item.isRequired"
        filterable
        placeholder="请选择"
        :style="{ width: item.width }"
      >
        <el-option
          v-for="(option, optionIndex) in itemOptions"
          :key="optionIndex"
          :label="option.label"
          :value="option.value"
        ></el-option>
      </el-select>
      <select-tree
        v-if="item.type == 'select-tree'"
        :propName="item.prop"
        :prop="form[item.prop]"
        :treeData="itemOptions"
        @handleChangeTreeNode="handleChangeTreeNode"
        :style="{ width: item.width }"
      ></select-tree>
      <el-cascader
        v-if="item.type == 'cascader'"
        v-model="form[item.prop]"
        :options="itemOptions"
        filterable
        clearable
        collapse-tags
        :props="{
          checkStrictly: item.isCheckStrictly,
          multiple: item.isMultiple,
        }"
        :show-all-levels="item.isShowAllLevels"
        :style="{ width: item.width }"
      ></el-cascader>
      <el-checkbox v-if="item.type == 'checkbox'" v-model="form[item.prop]">
      </el-checkbox>
      <el-checkbox
        v-if="item.type == 'checkbox-string'"
        v-model="form[item.prop]"
        true-label="1"
        false-label="0"
      >
      </el-checkbox>
      <el-checkbox
        v-if="item.type == 'checkbox-int'"
        v-model="form[item.prop]"
        :true-label="1"
        :false-label="0"
      >
      </el-checkbox>
      <el-checkbox
        v-if="item.type == 'checkbox-inner'"
        v-model="form[item.prop]"
        :style="{
          position: 'relative',
          left: '-30px',
          width: '0',
        }"
      >
      </el-checkbox>
      <el-checkbox
        v-if="item.type == 'checkbox-inner-string'"
        v-model="form[item.prop]"
        true-label="1"
        false-label="0"
        :style="{
          position: 'relative',
          left: '-30px',
          width: '0',
        }"
      >
      </el-checkbox>
      <el-checkbox
        v-if="item.type == 'checkbox-inner-int'"
        v-model="form[item.prop]"
        :true-label="1"
        :false-label="0"
        :style="{
          position: 'relative',
          left: '-30px',
          width: '0',
        }"
      >
      </el-checkbox>
      <el-date-picker
        v-if="item.type == 'date'"
        v-model="form[item.prop]"
        type="date"
        :placeholder="
          item.prop.toLowerCase().indexOf('start') > -1
            ? '开始日期'
            : item.prop.toLowerCase().indexOf('end') > -1
            ? '结束日期'
            : '选择日期'
        "
        :clearable="false"
        :style="{ width: item.width }"
      >
      </el-date-picker>
      <el-date-picker
        v-if="item.type == 'datetime'"
        v-model="form[item.prop]"
        type="datetime"
        value-format="yyyy-MM-dd HH:mm:ss"
        :placeholder="
          item.prop.toLowerCase().indexOf('start') > -1
            ? '开始时间'
            : item.prop.toLowerCase().indexOf('end') > -1
            ? '结束时间'
            : '选择日期时间'
        "
        :style="{ width: item.width }"
      >
      </el-date-picker>
    </template>
  </el-form-item>
</template>

<script>
export default {
  name: "SearchFormItem",
  props: {
    form: {
      type: Object,
      require: true,
    },
    item: {
      type: Object,
      require: true,
    },
  },
  computed: {
    itemOptions() {
      let itemOptions = [];
      if (this.item.optionJson) {
        try {
          itemOptions = JSON.parse(this.item.optionJson);
        } catch {
          itemOptions = [];
        }
      } else if (this.item.options) {
        itemOptions = this.item.options;
      }
      return itemOptions;
    },
  },
  created() {},
  methods: {
    handleChangeTreeNode(prop, value) {
      this.form[prop] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-inner {
  margin-right: 0;
}
</style>
