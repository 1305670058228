import request from '@/utils/request'

export function submit(url, data, flag) {
    return request({
        url,
        data: data || {},
        flag: flag || false
    })
}

export function getRoutes() {
    return request({
        url: '/api/config/getRouteConfig'
    })
}

export function getPageConfig(data) {
    return request({
        url: '/api/config/getPageConfig',
        data: data || {}
    })
}

export function exportFile(url, data) {
    return request({
        url,
        data: data || {},
        responseType: "blob"
    })
}