export function isArray(arg) {
    if (typeof Array.isArray === 'undefined') {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
    return Array.isArray(arg);
}

export function isString(str) {
    return typeof str === 'string' || str instanceof String;
}

export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
}

export function checkMobile(mobile) {
    return /^(0|86|17951)?(1[3-9])[0-9]{9}$/.test(mobile);
}

export function checkPassword(password) {
    return /^(?![\da-z]+$)(?![\dA-Z]+$)(?![\d!#$%^&*@-_]+$)(?![a-zA-Z]+$)(?![a-z!#$%^&*@-_]+$)(?![A-Z!#$%^&*@-_]+$)[\da-zA-z!#$%^&*@-_]{6,}$/.test(password)
}

export function numberFormat(val, isFloat = true, formatValue = 2, inputCheck = true) {
    if (!val) {
        val = '';
    }
    // 期望保留的最大小数位数
    let pointKeep = 0
    //是否浮点数
    if (isFloat) {
        pointKeep = 2
        if (typeof formatValue !== 'undefined') {
            if (typeof formatValue === 'string' || typeof formatValue === 'number') {
                pointKeep = parseInt(formatValue)
            } else if (typeof formatValue === 'object' && formatValue.precision) {
                // 支持新的小数点保留位配置
                pointKeep = formatValue.precision
            }
            if (!Number.isInteger(pointKeep) || pointKeep < 0) {
                pointKeep = 2
            }
        }
    }

    //非输入校验（纯粹格式化）
    if (!inputCheck) {
        if (typeof val !== "number") {
            val = parseFloat(val);
            if (isNaN(val)) {
                val = 0;
            }
        }
        return val.toFixed(pointKeep);
    }
    if (typeof val !== "number") {
        var floatValue = parseFloat(val);
        if (!isNaN(floatValue)) {
            let array = val.split('.');
            if (array.length > 1 && array[1].length > pointKeep) {
                val = floatValue.toFixed(pointKeep);
            }
        }
    }
    val += '';

    //最大值、最小值
    let min = null, max = null;
    if (typeof formatValue === 'object') {
        min = parseFloat(formatValue.min)
        max = parseFloat(formatValue.max)
    }

    if (min != null && !isNaN(min) && min >= 0) {
        // 不能是负数
        val = val.replace('-', '')
    }
    if (max != null && !isNaN(max) && max <= 0) {
        val = '-' + val
    }
    // 清除"数字"和"."以及"-"以外的字符
    val = val.replace(/[^-\d.]/g, '');

    // 只保留第一个'-'号
    val = val.replace(/-{2,}/g, "-").replace(/^-0+(\d)/, "-$1").replace(/(\d)-/g, "$1");
    // 只保留一个'.'号
    val = val.replace(/\.{2,}/g, ".");
    //第一位0开头，0后面为数字，则过滤掉，取后面的数字
    val = val.replace(/^0+(\d)/, "$1");
    // 将 '.' 替换成 '0.'
    val = val.replace(/^\./g, '0.');
    // 将 '-.' 替换成 '-0.'
    val = val.replace(/^-\./, '-0.');

    // 增加'-'号的支持
    const str = '^(\\-)*(\\d+)\\.(\\d{' + pointKeep + '}).*$';
    const reg = new RegExp(str)
    if (pointKeep === 0) {
        // 不需要小数点
        val = val.replace(reg, '$1$2')
    } else {
        // 通过正则保留小数点后指定的位数
        val = val.replace(reg, '$1$2.$3')
    }

    if (val !== '') {
        if (min != null && !isNaN(min) && parseFloat(val) < min) {
            val = min
        }
        if (max != null && !isNaN(max) && parseFloat(val) > max) {
            val = max
        }
    }
    return val;
}