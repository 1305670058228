<template>
  <div class="tree-wrapper">
    <div class="tree-title"><i class="el-icon-finished" />{{ treeTitle }}</div>
    <el-tree
      ref="tree"
      :data="treeData"
      default-expand-all
      :expand-on-click-node="false"
      highlight-current
      check-strictly
      @node-click="handleNodeClick"
    >
      <span slot-scope="{ node, data }">
        <i v-if="data.children" class="el-icon-folder" />
        <i v-else class="el-icon-document" />
        {{ node.label }}
      </span>
    </el-tree>
  </div>
</template>
<script>
export default {
  name: "EditTree",
  data() {
    return {};
  },
  props: {
    treeTitle: {
      type: String,
      required: true,
    },
    treeData: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      const treeWrapper = document.getElementsByClassName("tree-wrapper")[0];
      const elTree = treeWrapper.getElementsByClassName("el-tree")[0];
      elTree.style.height =
        document.getElementById("main-container").offsetHeight -
        elTree.offsetTop +
        2 +
        "px";
      elTree.style.overflow = "auto";
    });
    window.onresize = function () {
      const treeWrapper = document.getElementsByClassName("tree-wrapper")[0];
      const elTree = treeWrapper.getElementsByClassName("el-tree")[0];
      elTree.style.height =
        document.getElementById("main-container").offsetHeight -
        elTree.offsetTop +
        2 +
        "px";
    };
  },
  methods: {
    handleNodeClick(data) {
      this.$emit("handleNodeClick", data);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>