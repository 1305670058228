<template>
  <div class="layout-middle">
    <layout-navbar :menus="menus" :activeRouteID="activeRouteID" @handleMenuClick="handleMenuClick"></layout-navbar>
    <layout-sub-navbar :isCollapse="isCollapse" :menus="menus" :activeRouteID="activeRouteID"></layout-sub-navbar>
    <layout-main :isCollapse="isCollapse"></layout-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutMiddle",
  data() {
    return {
      homeRouteID: "202208250850000001",
      activeRouteID: "202208250850000001",
      isCollapse: true,
    };
  },
  computed: {
    ...mapGetters({
      routes: "routes/routes",
    }),
    menus() {
      return this.routes.filter((route) => {
        return route.meta && route.meta.isMenu && !route.meta.isHidden;
      });
    },
  },
  methods: {
    handleMenuClick(routeID) {
      if (routeID == this.homeRouteID) {
        this.isCollapse = true;
        this.activeRouteID = routeID;
        this.$router.push({
          path: '/home',
        });
        return;
      }
      this.isCollapse = this.activeRouteID == routeID ? !this.isCollapse : false;
      this.activeRouteID = routeID;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-middle {
  position: relative;
  height: calc(100vh - #{$base-header-height} - #{$base-footer-height});
}
</style>