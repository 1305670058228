import { unAuthRoutes } from '@/router'
import { getRoutes } from '@/api/base'
import { convertRouter } from '@/utils/handleRoutes'

const state = () => ({
    routes: []
});

const getters = {
    routes: (state) => state.routes
};

const mutations = {
    setRoutes(state, routes) {
        state.routes = unAuthRoutes.concat(routes);
    }
};

const actions = {
    async setRoutes({ commit }) {
        let { data } = await getRoutes();
        data.push({ path: '*', redirect: '/404', hidden: true });
        let accessRoutes = convertRouter(data);
        commit('setRoutes', accessRoutes);
        return accessRoutes;
    }
};

export default { state, getters, mutations, actions }