<template>
  <div class="layout-header">
    <span class="header-left">
      <img :src="require('@/assets/img/logo/logo.png')" />
      <el-divider direction="vertical"></el-divider>
      <span>SaaS电商ERP管理中心</span>
    </span>
    <div class="header-right">
      <div>
        <el-avatar
          size="large"
          src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
        ></el-avatar>
        <span>{{ loginData.UserName }}，{{ cnTime }}</span>
      </div>
      <el-divider direction="vertical" />
      <div>
        <span class="login-out" @click="logout">退出登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/user";
import { getCnTime } from "@/utils/tool";

export default {
  name: "LayoutHeader",
  data() {
    return {
      cnTime: "",
      loginData: {},
      dialogVisible: false,
    };
  },
  created() {
    this.$nextTick(async () => {
      this.cnTime = getCnTime();
      const { data } = await getUserInfo();
      this.loginData = { ...data };
    });
  },
  methods: {
    logout() {
      this.$baseConfirm(
        "您确定要退出" + this.$baseTitle + "管理中心吗?",
        null,
        async () => {
          await this.$store.dispatch("tabsBar/delAllRoutes");
          await this.$store.dispatch("user/logout");
          this.$router.push("/login");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-header {
  width: 100vw;
  background: $base-color-blue;
  height: $base-header-height;
  line-height: $base-header-height;
  position: relative;
  color: rgba(255, 255, 255, 1);
  .header-left {
    font-size: 20px;
    font-weight: bold;
    img {
      margin-left: 20px;
      width: 172px;
      height: 40px;
      vertical-align: middle;
      object-fit: contain;
    }
    .el-divider {
      margin: 0 19px;
      height: 26px;
    }
  }
  .header-right {
    position: absolute;
    top: 0;
    right: 11px;
    font-size: 14px;
    font-weight: 400;
    height: 100%;

    > div {
      display: inline-block;
      padding: 0 20px;
      height: 100%;
      cursor: pointer;
      position: relative;
      
      &:hover {
        background: white;
        color: rgba(0, 117, 227, 1);
      }
    }

    .el-avatar {
      vertical-align: middle;
      margin-right: 12px;
      img {
        height: 16px;
        width: 16px;
        object-fit: contain;
      }
    }

    .el-divider {
      height: $base-header-height;
      width: 1px;
      background-color: rgba(0, 109, 211, 1);
      margin: 0px;
      padding: 0px;

      &.el-divider--vertical {
        vertical-align: top;
      }
    }
  }
}
</style>
