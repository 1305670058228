<template>
  <el-form
    ref="form"
    :model="formConfig.data"
    :rules="formConfig.rules"
    :inline="formConfig.isInline"
    label-position="right"
    :label-width="formConfig.labelWidth"
    :inline-message="true"
  >
    <edit-form-item
      v-for="item in formConfig.items"
      :key="item.formItemID"
      :form="formConfig.data"
      :item="item"
      :labelWidth="formConfig.labelWidth"
      :tipWidth="formConfig.tipWidth"
    ></edit-form-item>
    <slot></slot>
  </el-form>
</template>

<script>
export default {
  name: "EditForm",
  props: {
    formConfig: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  methods: {
    clearValidate() {
      this.$refs.form.clearValidate();
    },
    validate(callback) {
      this.$refs.form.validate(callback);
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
  },
};
</script>
