import Vue from 'vue'
import VueRouter from 'vue-router'
import { publicPath, routerMode } from '@/config'

Vue.use(VueRouter);

export const unAuthRoutes = [
    {
        path: '/login',
        component: () => import('@/views/page/login'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/page/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/page/401'),
        hidden: true
    },
    {
        path: '/402',
        component: () => import('@/views/page/402'),
        hidden: true
    }
];

const router = createRouter()

function createRouter() {
    return new VueRouter({
        base: publicPath,
        mode: routerMode,
        scrollBehavior: () => ({
            y: 0,
        }),
        routes: [...unAuthRoutes],
    })
}

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router

