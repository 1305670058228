import request from '@/utils/request'
import { encrypt } from '@/utils/rsaEncrypt'

export function getPublicKey() {
  return request({
    url: '/api/user/getPublicKey'
  })
}

export async function doLogin(data) {
  data.password = await encrypt(data.password);
  return request({
    url: '/api/user/login',
    data
  })
}

export function getUserInfo() {
  return request({
    url: '/api/user/getUserInfo'
  })
}

export function doLogout() {
  return request({
    url: '/api/user/loginOut'
  })
}


