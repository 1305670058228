export function getCnTime() {
  const hour = new Date().getHours();
  const thisTime = hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好';
  return thisTime;
}

export function getDateTime(addDay = 0) {
  const date = new Date();
  date.setDate(date.getDate() + addDay);
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  let hours = date.getHours();
  if (hours < 10) {
    hours = '0' + hours;
  }
  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  let seconds = date.getSeconds();
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return `${date.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getDate(addDay = 0) {
  const date = new Date();
  date.setDate(date.getDate() + addDay);
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  return `${date.getFullYear()}-${month}-${day}`;
}

export function getRandomNum() {
  const date = new Date();
  return `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getUTCMilliseconds()}`;
}

export function getColumn(columnName, array) {
  let columnArr = [];
  if (!array || array.length == 0 || !Object.prototype.hasOwnProperty.call(array[0], columnName)) {
    return columnArr;
  }
  for (var item of array) {
    columnArr.push(item[columnName]);
  }
  return columnArr;
}

export function getColumnValue(columnName, array) {
  let columnValue = '';
  if (!array || array.length == 0 || !Object.prototype.hasOwnProperty.call(array[0], columnName)) {
    return columnValue;
  }
  for (var item of array) {
    columnValue += ',' + item[columnName];
  }
  return columnValue.substring(1);
}

export function formatDatetime(value, type = '') {
  if (!value) {
    return '';
  }
  switch (type) {
    case 'strong|no-second': {
      let datetime = value.split(' ');
      let date = datetime.length > 0 ? datetime[0] : '';
      let time = datetime.length > 1 ? datetime[1] : '';
      time = time.substring(0, time.lastIndexOf(':'));
      return `<strong>${date}</strong> ${time}`;
    }
    default:
      return value;
  }
}
