import { loadingText, messageOffset, messageDuration, title } from '@/config'
import * as lodash from 'lodash'
import { Loading, Message, MessageBox, Notification } from 'element-ui'
import store from '@/store'
import { getAccessToken } from '@/utils/accessToken'

const accessToken = store.getters['user/accessToken']
const layout = store.getters['settings/layout']

const install = (Vue, opts = {}) => {
  /* 全局accessToken */
  Vue.prototype.$baseAccessToken = () => {
    return accessToken || getAccessToken()
  }

  /* 全局标题 */
  Vue.prototype.$baseTitle = (() => {
    return title
  })()

  /* 全局加载层 */
  Vue.prototype.$baseLoading = (index, text) => {
    let loading
    if (!index) {
      loading = Loading.service({
        lock: true,
        text: text || loadingText,
        background: 'hsla(0,0%,100%,.8)',
      })
    } else {
      loading = Loading.service({
        lock: true,
        text: text || loadingText,
        spinner: 'vab-loading-type' + index,
        background: 'hsla(0,0%,100%,.8)',
      })
    }
    return loading
  }

  /* 全局多彩加载层 */
  Vue.prototype.$baseColorfullLoading = (index, text) => {
    let loading
    if (!index) {
      loading = Loading.service({
        lock: true,
        text: text || loadingText,
        spinner: 'dots-loader',
        background: 'hsla(0,0%,100%,.8)',
      })
    } else {
      switch (index) {
        case 1:
          index = 'dots'
          break
        case 2:
          index = 'gauge'
          break
        case 3:
          index = 'inner-circles'
          break
        case 4:
          index = 'plus'
          break
      }
      loading = Loading.service({
        lock: true,
        text: text || loadingText,
        spinner: index + '-loader',
        background: 'hsla(0,0%,100%,.8)',
      })
    }
    return loading
  }

  /* 全局Message */
  Vue.prototype.$baseMessage = (message, type) => {
    Message({
      offset: messageOffset,
      showClose: true,
      message: message,
      type: type,
      dangerouslyUseHTMLString: true,
      duration: messageDuration,
    })
  }

  /* 全局Alert */
  Vue.prototype.$baseMessageBox = (content, title, type = '', callback1, confirmText, showCancel = false, callback2, cancelText) => {
    MessageBox.alert(content, title || '温馨提示', {
      confirmButtonText: confirmText || '确定',
      showCancelButton: showCancel,
      cancelButtonText: cancelText || "关闭",
      dangerouslyUseHTMLString: true,
      showClose: false,
      type: type
    }).then(() => {
      if (callback1) {
        callback1()
      }
    }).catch(() => {
      if (callback2) {
        callback2()
      }
    })
  }

  /* 全局Confirm */
  Vue.prototype.$baseConfirm = (content, title, callback1, callback2) => {
    MessageBox.confirm(content, title || '温馨提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false,
      type: 'warning',
    }).then(() => {
      if (callback1) {
        callback1()
      }
    }).catch(() => {
      if (callback2) {
        callback2()
      }
    })
  }

  /* 全局Notification */
  Vue.prototype.$baseNotify = (message, title, type, position) => {
    Notification({
      title: title,
      message: message,
      position: position || 'top-right',
      type: type || 'success',
      duration: messageDuration,
    })
  }

  /* 全局TableHeight */
  Vue.prototype.$baseTableHeight = (formType) => {
    let height = window.innerHeight
    let paddingHeight = 400
    const formHeight = 50

    if (layout === 'vertical') {
      paddingHeight = 365
    }

    if ('number' == typeof formType) {
      height = height - paddingHeight - formHeight * formType
    } else {
      height = height - paddingHeight
    }
    return height
  }

  /* 全局lodash */
  Vue.prototype._ = lodash

  /* 全局事件总线 */
  Vue.prototype.$baseEventBus = new Vue()

  /* 获取表格查询参数 */
  Vue.prototype.getTableParam = ((rows, isNeedRowKey, isMultiSelectRow, rowKey, paramJson) => {
    let param = {};
    try {
      param = paramJson ? JSON.parse(paramJson) : {};
    } catch {
      param = {};
    }
    if (isNeedRowKey && rowKey) {
      param[rowKey + (isMultiSelectRow ? "s" : "")] = `?${rowKey}?`;
    }
    for (let key in param) {
      if (!(param[key] + '').includes("?")) {
        continue;
      }
      var curKey = param[key].slice(
        param[key].indexOf("?") + 1,
        param[key].lastIndexOf("?")
      );
      if (!curKey) {
        param[key] = "";
        continue;
      }
      if (!isMultiSelectRow && rows.length == 1) {
        param[key] = rows[0][curKey];
      } else {
        param[key] = rows.map((row) => row[curKey]).join();
      }
    }
    return param;
  })

  /* 获取表单验证规则 */
  Vue.prototype.getFormRules = ((formItems, initData) => {
    let items = [];
    let rule = {};
    (formItems || []).forEach((item) => {
      let newItem = { ...item };
      newItem.options = [];
      if (item.optionField && item.optionField in initData) {
        newItem.options = initData[item.optionField];
      }
      if (item.type == "select") {
        newItem.options.unshift({ label: "请选择", value: item.beforeSetValue });
      }
      items.push(newItem);
      var rules = [];
      if (item.type == "select" && item.isRequired) {
        rules.push({
          required: true,
          validator: (rule, value, callBack) => {
            if (value == item.beforeSetValue) {
              callBack('请选择');
            } else {
              callBack();
            }
          }, trigger: "blur"
        });
      }
      if (item.type == "cascader" && item.isRequired) {
        rules.push({
          required: true,
          validator: (rule, value, callBack) => {
            if (!value || value.length <= 0 || value[0] == null) {
              callBack('请选择');
            } else {
              callBack();
            }
          }, trigger: "blur"
        });
      }
      if (item.type.includes("input")) {
        if (item.isRequired) {
          rules.push({
            required: item.isRequired,
            trigger: "blur",
            message: '不可为空',
          });
        }
        if (item.min > 0 || item.max > 0) {
          rules.push({
            min: item.min,
            max: item.max,
            trigger: "blur",
            message: `长度在${item.min}到${item.max}个字符`,
          });
        }
      }
      if (rules.length > 0) {
        rule[item.prop] = rules;
      }
    });
    return { items: items, rules: rule };
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default install