<template>
  <div class="layout-navbar">
    <div class="navbar">
      <div
        class="menu"
        v-for="(menu, index) in menus"
        :key="index"
        :class="{
          active: menu.meta.routeID == activeRouteID,
        }"
        @click="handleClick(menu.meta.routeID)"
      >
        <i :class="menu.meta.icon"></i>
        <div>{{ menu.meta.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutNavbar",
  props: {
    menus: {
      type: Array,
      require: true,
    },
    activeRouteID: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClick(routeID) {
      this.$emit("handleMenuClick", routeID);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-navbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: black;
  width: 48px;
  overflow: auto;
  font-size: 12px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .navbar {
    min-height: 620px;
    text-align: center;
    width: 100%;
    float: left;
    color: white;
    .menu {
      line-height: 20px;
      height: 60px;
      cursor: pointer;
      &:hover {
        background: white;
        color: rgba(0, 117, 227, 1);
      }

      &.active {
        background: white;
        color: rgba(0, 117, 227, 1);
      }

      i {
        font-size: 20px;
        margin-top: 10px;
      }
    }
  }
}
</style>