<template>
  <div v-if="routerView">
    <transition mode="out-in" name="fade-transform">
      <keep-alive :include="cachedRoutes" :max="keepAliveMaxNum">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { keepAliveMaxNum } from "@/config";

export default {
  name: "LayoutMainContent",
  data() {
    return {
      keepAliveMaxNum,
      routerView: true,
    };
  },
  computed: {
    ...mapGetters({
      visitedRoutes: "tabsBar/visitedRoutes",
    }),
    cachedRoutes() {
      const cachedRoutesArr = [];
      this.visitedRoutes.forEach((route) => {
        if (route.meta.isKeepAlive) {
          cachedRoutesArr.push(route.name);
        }
      });
      return cachedRoutesArr;
    },
    key() {
      return this.$route.path;
    },
  },
  created() {
    //重载所有路由
    this.$baseEventBus.$on("reload-router-view", () => {
      this.routerView = false;
      this.$nextTick(() => {
        this.routerView = true;
      });
    });
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
</style>
